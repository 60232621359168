import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Tag, Typography, Space, Divider } from "antd"

import "./BlogMasthead.less"

const BlogMasthead = props => {
  const { Title, Text } = Typography

  const dateString = props.date
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  const [day, month, year] = dateString.split("-")
  const date = new Date(`${year}-${month}-${day}`)

  const formattedDate = date.toLocaleDateString("id-ID", options)

  // Split the formatted date into day and date parts
  const [dayName, fullDate] = formattedDate.split(", ")

  // Now you can assign them to separate variables
  const dayOfWeek = dayName
  const fullDateString = fullDate

  return (
    <div className="blogmasthead">
      <figure className="blogmasthead-media">
        <img
          className="blogmasthead-image"
          src={props.image}
          alt={props.description}
        />
        <figcaption className="blogmasthed-imagecaption">
          {props.imageCaption}
        </figcaption>
      </figure>

      <div className="blogmasthead-content">
        <div className="detail">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Tag>{props.category}</Tag>
            <Title>{props.title}</Title>
          </ScrollAnimation>
        </div>

        <div className="meta">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Space
              size={"medium"}
              split={<Divider type="vertical" />}
              style={{ marginBottom: 20 }}
            >
              <Space>
                <Text className="label">Penulis</Text>
                <Text>{props.author}</Text>
              </Space>
              <Space>
                <Text className="label">{`${dayOfWeek},`}</Text>
                <Text>{fullDateString}</Text>
              </Space>
            </Space>
            {props.description && (
              <Title level={3} italic>
                Pendidikan Tinggi membuka wawasan dan banyak pintu pilihan, bagi
                Arip wawasan itu membuka pilihan untuk berkarier tingkat
                International.
              </Title>
            )}
          </ScrollAnimation>
        </div>
      </div>
    </div>
  )
}

export default BlogMasthead
