import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import { Row, Col, Breadcrumb, message } from "antd"
import { useI18next } from "gatsby-plugin-react-i18next"

import PageNotFound from "../../404"
import Layout from "../../../app/layout"

import Seo from "../../../components/seo"
import Container from "../../../components/Container"
import Newsletter from "../../../components/Newsletter"
import BlogMasthead from "../../../components/BlogMasthead"
import CardNews from "../../../components/CardNews"
import {
  ChevronRight,
  FacebookCircle,
  Twitter,
  Mail,
  Link as SVGLink,
} from "../../../components/Icon"

import "./single.less"

const SinglePage = ({
  pageContext: { data: dataAll = {}, relatedArticles = [], language = "id" },
}) => {
  const { t } = useI18next()
  const { Item } = Breadcrumb
  const data = dataAll[language]
  const [backTo, setBackTo] = useState("")
  const [currentUrl, setCurrentUrl] = useState("")

  useEffect(() => {
    setCurrentUrl(window.location.href)
  }, [])

  useEffect(() => {
    setBackTo((window.location.search || "").replace("?cat=", ""))
  }, [])

  if (!data) return <PageNotFound />

  return (
    <Layout>
      <Seo
        title={data.title}
        description={data.description}
        meta={{ image: data.image }}
      />
      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>
            <Link to={backTo || "/informasi/berita/"}>{t("Berita")}</Link>
          </Item>
          <Item>{data.title}</Item>
        </Breadcrumb>
      </Container>
      <Container>
        <BlogMasthead
          title={data.title}
          image={data.image}
          category={data.news_category}
          date={data.created_at}
          author={data.writer_name}
          photographer={data.photographer_name}
          imageCaption={data.caption_image}
          description={data.description}
        />
      </Container>
      <Container>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <article className="article section text-medium">
            <Row
              gutter={40}
              justify="space-between"
              style={{ margin: "0 -15px" }}
            >
              <Col span={24} md={14} style={{ padding: "0 15px" }}>
                <Row
                  justify="space-between"
                  gutter={24}
                  style={{ margin: "0 -15px" }}
                >
                  <Col
                    span={12}
                    md={{ span: 3, order: 1 }}
                    xs={{ span: 24, order: 2 }}
                  >
                    <div className="social-share sticky-sidebar">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Bagikan ke Facebook"
                      >
                        <FacebookCircle />
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet/?text=&url=${currentUrl}`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Bagikan ke X"
                      >
                        <Twitter width="24px" height="24px" />
                      </a>
                      <a
                        href={`mailto:?Subject=${data.title}&body=${currentUrl}`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Kirim email"
                      >
                        <Mail />
                      </a>
                      <div style={{ display: "inline-block" }}>
                        <SVGLink
                          target="_blank"
                          onClick={() => {
                            window.navigator.clipboard.writeText(currentUrl)
                            message.success("Copied to clipboard")
                          }}
                          rel="noreferrer"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    span={12}
                    md={{ span: 21, order: 1 }}
                    xs={{ span: 24, order: 1 }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: data.content }} />
                  </Col>
                </Row>
              </Col>
              <Col span={24} md={8} style={{ padding: "0 15px" }}>
                <div offsetTop={80} className="sticky-sidebar">
                  <Newsletter />
                </div>
              </Col>
            </Row>
          </article>
        </ScrollAnimation>
      </Container>
      {relatedArticles.length > 0 && (
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h1 className="section-title-huge">{t("Berita Terkait")}</h1>
          </ScrollAnimation>
          <Row gutter={30} style={{ marginTop: 24 }}>
            {relatedArticles.map((item, i) => (
              <Col span={24} md={8} key={i}>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <CardNews
                    title={item.title}
                    category={item.news_category}
                    image={item.image}
                    date={item.created_at}
                    url={item.slug}
                  />
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </Layout>
  )
}

export default SinglePage

export const query = graphql`
  query NewsSingleQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
